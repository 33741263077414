import Cookies from 'js-cookie';
import CartDispatcherCustom from 'pwa/src/store/Cart/Cart.dispatcher';

import MyAccountQuery from 'Query/MyAccount.query';
import MyAccountNewsletterSubscriptionQuery from 'Query/MyAccountNewsletterSubscription.query';
import { CUSTOMER } from 'Route/MyAccount/MyAccount.config';
import {
    MyAccountDispatcher as SourceMyAccountDispatcher,
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    updateCustomerDetails,
    updateCustomerSignInStatus,
    updateIsLoading,
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    setAuthorizationToken,
} from 'Util/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { getCustomerIsSubscribed } from 'Util/Customer/Gtm';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/Config';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { executePost } from 'Util/Request/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS,
};

/** @namespace Pwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        /* const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        } */

        return executePost(prepareQuery([query])).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                BrowserDatabase.setItem(customer.firstname, 'user_firstname', ONE_MONTH_IN_SECONDS);
                BrowserDatabase.setItem(customer.lastname, 'user_lastname', ONE_MONTH_IN_SECONDS);
            },
            /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/catch */
            (_error) => {
                this.logout(false, dispatch); // MCT-1357: FIX error when auth-token is not valid anymore
            }
        );
    }

    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);
        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);
        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token

        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magent<o capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
        );

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        GTMDispatcher.then(
            ({ default: dispatcher }) => dispatcher.pushSignIn(dispatch)
        );

        const tokenBrowser = BrowserDatabase.getItem('auth_token');
        let domainOrigin = 'mondoconv.it';

        if (window.location.host === 'mondoconv.valueservice.cloud') {
            domainOrigin = (window.location.host).replace('.valueservice.cloud', 'it');
        }
        const date = new Date();
        date.setHours(date.getHours() + 1);
        Cookies.set('auth_token', tokenBrowser, { expires: date, path: '/', domain: `.${domainOrigin}` });
        // Force cart update after login. Due to calls optimizations, if no cart
        // information is provided, no call will be performed to backend.
        // A user just logged in will not see his/her cart updated.
        // Explicitly calling refreshCart on login, we force it.
        CartDispatcherCustom.refreshCart(dispatch);
    }

    async signInSso(dispatch) {
        const cartDispatcher = (await CartDispatcher).default;

        const guestCartToken = getGuestQuoteId();

        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);
        // await this.requestCustomerData(dispatch);
        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));
        GTMDispatcher.then(
            ({ default: dispatcher }) => dispatcher.pushSignIn(dispatch)
        );
        CartDispatcherCustom.refreshCart(dispatch);
    }

    async createAccount(options = {}, dispatch) {
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));
        try {
            await fetchMutation(mutation);
        } catch (error) {
            dispatch(updateIsLoading(false));
            dispatch(showNotification('error', getErrorMessage(error)));
        }

        dispatch(updateIsLoading(false));
        dispatch(showNotification('warning', __('Your account has been created successfully!New')));

        GTMDispatcher.then(
            ({ default: dispatcher }) => dispatcher.pushCreateAccount(dispatch)
        );

        if (getCustomerIsSubscribed()) {
            GTMDispatcher.then(
                ({ default: dispatcher }) => dispatcher.pushNewsletterAccount(dispatch)
            );
        }
        history.push('/account/login');
    }

    logout(authTokenExpired = false, dispatch) {
        super.logout(authTokenExpired, dispatch);
        BrowserDatabase.deleteItem('user_firstname');
        BrowserDatabase.deleteItem('user_lastname');
        Cookies.remove('auth_token');
    }

    requestCustomerDataWithNewsletterSubscriptionStatus(dispatch) {
        const query = MyAccountNewsletterSubscriptionQuery.getCustomerDataWithNewsletterSubscriptionStatus();

        /* const customer = BrowserDatabase.getItem(CUSTOMER) || {};

        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        } */

        return executePost(prepareQuery([query])).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerDataWithNewsletterSubscriptionStatus/executePost/then */
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
            },
            /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerDataWithNewsletterSubscriptionStatus/executePost/then/catch */
            (_error) => {
                this.logout(false, dispatch); // MCT-1357: FIX error when auth-token is not valid anymore
            }
        );
    }
}

export default new MyAccountDispatcher();
